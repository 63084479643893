<template>
  <div id="app" class="app">
    <router-view/>
  </div>
</template>

<script>
import { setMeta } from './utils/siteMeta'
const title = "SEVEN's blog"
const description = "SEVEN's blog - https://seven.geekfun.club/"
import avatar from './assets/images/me.jpeg'
export default {
  name: 'App',
  mounted: function () {
    setMeta(title, description, avatar, 'https://seven.geekfun.club')
  }
  
}
</script>

<style scoped>
.app {
  width: 100%;
  height: 100%;
}
</style>
