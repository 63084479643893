export const getSiteMeta = (title, description, image, url, tags = [], published = new Date(), modified = new Date()) => ([
    {
        vimid: 'schema-org-markup-name',
        itemprop: "name",
        content: title
      },
      {
        vimid: 'schema-org-markup-description',
        itemprop: "description",
        content: description,
      },
      {
        vimid: 'schema-org-markup-name',
        itemprop: "image",
        content: image,
      },
    {
    vimid: 'twitter:card',
    name: 'twitter:card',
    content: image,
  },
  {
    vimid: 'twitter:site',
    name: 'twitter:site',
    content: url,
  },
   {
    vimid: 'twitter:title',
    name: 'twitter:title',
    content: title,
  },
  {
    vimid: 'twitter:description',
    name: 'twitter:description',
    content: description,
  },
  {
    vimid: 'twitter:image:src',
    name: 'twitter:image:src',
    content: image,
  },
  {
    vimid: 'og:title',
    name: 'og:title',
    content: title,
  },
  {
    vimid: 'og:type',
    name: 'og:type',
    content: 'article',
  },
  {
    vimid: 'og:url',
    name: 'og:url',
    content: url,
  },
  {
    vimid: 'og:image',
    name: 'og:image',
    content: image,
  },
  {
    vimid: 'og:description',
    name: 'og:description',
    content: description,
  },
  {
    vimid: 'og:site_name',
    name: 'og:site_name',
    content: "SEVEN's blog",
  },
  {
    vimid: 'og:published_time',
    name: 'og:published_time',
    content: published instanceof Date ? published.toDateString(): published
  },
  {
    vimid: 'og:modified_time',
    name: 'og:modified_time',
    content: modified instanceof Date ? modified.toDateString(): modified
  },
  {
    vimid: 'og:tag',
    name: 'og:tag',
    content: tags.toString() 
  },
  ])

  export const setMeta = (title, description, image, url, tags = [], published = new Date(), modified = new Date()) => {
    document.title = title;
    document.querySelector('meta[name="description"]').setAttribute("content", `${description} | SEVEN's blog - ${url}`);
    document.querySelector('meta[name="keywords"]').setAttribute("content", `${tags.join(',')}`);
    const metas = getSiteMeta(title, description, image, url, tags, published, modified);
    metas.forEach(({name, content, itemprop}) => {
        if (itemprop) {
            const attr = document.querySelector(`meta[itemprop="${itemprop}"]`)
            if(!attr){
                const newMeta = document.createElement('meta');
                newMeta.setAttribute('itemprop', itemprop);
                newMeta.setAttribute("content", content);
                document.getElementsByTagName('head')[0].appendChild(newMeta);
            } else {
                attr.setAttribute("content", content);
            }
        } else {
            const attr = document.querySelector(`meta[name="${name}"]`);
            if(!attr) {
                const newMeta = document.createElement('meta');
                newMeta.setAttribute('name', name);
                newMeta.setAttribute("content", content);
                document.getElementsByTagName('head')[0].appendChild(newMeta);
            } else {
                attr.setAttribute("content", content);
            }
        }
    });
  }